<template>
  <div class="wrap">
    <div class="banner">
      <img v-if="clientWidth > 750" :src="bannerImg" alt="">
      <img v-else :src="bannerImg_m" alt="">
    </div>
    <div class="module" v-if="false">
      <div class="module-intro" v-if="isNoAddressNav">
        <p>“剑指无界，共盟新程--2023年剑维软件加速实现工控领域数字化转型” 巡展活动，即将在上海、广州、成都和北京拉开帷幕。此次巡展活动专门邀请了拥有丰富行业经验的专家，与大家共同探讨工控领域如何深度应用数字化技术及解决方案，如何提升运营控制、生产管理等智能化水平，实现资产优化和精准决策。</p>
        <p>剑指无界，共盟新程。让我们一起踏上工业互联经济发展的新征程，应对减碳和数字化转型的挑战。抓住新机遇，创造新价值。期待您的参与。</p>
      </div>
      <div class="module-intro" v-else>
        <p>“剑指无界，共盟新程--2023年剑维软件加速实现工控领域数字化转型” 巡展活动，即将在上海、广州、成都和北京拉开帷幕。共同探讨工业自动化控制领域如何加速实现数字化转型，实现可持续发展。</p> 
        <p>剑维软件作为世界领先的工业软件供应商，携手合作伙伴，致力于帮助客户加速工控领域数字化转型。此次巡展活动专门邀请了拥有丰富行业经验的专家，与大家共同探讨工控领域如何深度应用数字化技术及解决方案，如何提升运营控制、生产管理等智能化水平，实现资产优化和精准决策。</p>
        <p>剑指无界，共盟新程。让我们一起踏上工业互联经济发展的新征程，应对减碳和数字化转型的挑战。抓住新机遇，创造新价值。期待您的参与。</p>
      </div>
      <div class="module-title">点击下方按钮了解不同城市日程安排</div>
      <div class="module-box flex7">
        <div class="module-box-content flex0" :class="isNoAddressNav && clientWidth > 750 ? (currentIndex == index ? 'select-active module-box-address' : 'module-box-address') : currentIndex == index ? 'select-active' : ''" :style="clientWidth > 750 ? currentIndex == index ? (isNoAddressNav ? item.image_address_active : item.image_active) : (isNoAddressNav ? item.image_address : item.image) : currentIndex == index ? item.image_m_active : item.image_m" v-for="(item, index) in roles" :key="item.value" @click="clickRadio(item, index)">
          <div class="module-content-nav">
            <div class="nav-time">
              <img :src="currentIndex == index ? timeImg_active : timeImg" alt="">
              <span>{{item.name}}</span>
            </div>
            <div class="nav-address">
              <img :src="currentIndex == index ? addressImg_active : addressImg" alt="">
              <span>{{item.address}}</span>
            </div>
          </div>
          <div class="module-content-address" v-if="isNoAddressNav && clientWidth > 750">
            <p>{{item.addressDetail1}}</p>
            <p>{{item.addressDetail2}}</p>
          </div>
        </div>
      </div>

      <div class="module-date-box">
        <div class="address-mobile" v-if="isNoAddressNav && clientWidth <= 750">
          <p>{{addressDetail1}}</p>
          <p>{{addressDetail2}}</p>
        </div>
        <div class="date-title">日程安排</div>
        <div class="item">
          <table>
            <tbody>
              <tr>
                <td class="item-header-left">时间</td>
                <td class="item-header-center">主题</td>
                <!-- <td class="item-header-right">嘉宾</td> -->
              </tr>
              <tr v-for="(item, index) in dateData" :key="index">
                <td>{{item.time}}</td>
                <td>{{item.content}}</td>
                <!-- <td>{{item.people}}</td> -->
              </tr>
            </tbody>
          </table>
          <div class="item-more">* 会议内容将不断更新完善。</div>
        </div>
        <div class="date-title">特邀嘉宾</div>
        <div v-if="currentIndex == 0">
          <div class="people-big-box" v-if="clientWidth <= 750">
            <div class="people people_header" :class="item.type && item.type == 'small' ? 'small_box' : ''" v-for="(item, index) in peopleBigData_m" :key="index">
              <img :src="item.img" :class="item.type && item.type == 'small' ? 'small_img' : ''" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
          <div class="people-box" v-if="clientWidth > 750">
            <div class="people" :class="currentIndex == 0 ? 'chengdu_m_box' : ''" v-for="(item, index) in peopleData" :key="index">
              <img :src="item.img" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex == 1">
          <div class="people-big-box">
            <div class="people people_header" :class="item.type && item.type == 'small' ? 'small_box' : ''" v-for="(item, index) in peopleBigData" :key="index">
              <img :src="item.img" :class="item.type && item.type == 'small' ? 'small_img' : ''" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
          <div class="people-box">
            <div class="people"  v-for="(item, index) in peopleData" :key="index">
              <img :src="item.img" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
            <div class="people">
              <img :src="peopleMoreImg" alt="">
              <div class="people-content">
                <p class="people-name">更多大咖</p>
                <p class="people-name">现场揭晓</p>
                <p class="people-address"></p>
                <p class="people-position"></p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex == 2">
          <div class="people-big-box" v-if="clientWidth <= 750">
            <div class="people people_header" :class="item.type && item.type == 'small' ? 'small_box' : ''" v-for="(item, index) in peopleBigData_m" :key="index">
              <img :src="item.img" :class="item.type && item.type == 'small' ? 'small_img' : ''" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
          <div class="people-box" v-if="clientWidth > 750">
            <div class="people" :class="currentIndex == 2 ? 'chengdu_m_box' : ''" v-for="(item, index) in peopleData" :key="index">
              <img :src="item.img" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex == 3">
          <div class="people-big-box" v-if="clientWidth <= 750">
            <div class="people people_header" :class="item.type && item.type == 'small' ? 'small_box' : ''" v-for="(item, index) in peopleBigData" :key="index">
              <img :src="item.img" :class="item.type && item.type == 'small' ? 'small_img' : ''" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
          <div class="people-box" v-if="clientWidth > 750">
            <div class="people" :class="currentIndex == 2 ? 'chengdu_m_box' : ''" v-for="(item, index) in peopleData" :key="index">
              <img :src="item.img" alt="">
              <div class="people-content">
                <p class="people-name">{{item.name}}</p>
                <p class="people-address" v-if="clientWidth > 750" v-html="item.address"></p>
                <p class="people-address" v-if="clientWidth <= 750" v-html="item.address_m"></p>
                <p class="people-position">{{item.position}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="date-title">活动主办方</div>
        <div class="activity-box">
          <div class="activity" v-for="(item, index) in activityData" :key="index">
            <img :src="item.img" alt="">
          </div>
        </div>
        <div class="ercode-box" v-if="!isNoAddressNav">
          <div class="ercode-img">
            <img :src="isNoAddressNav ? ercode_haveaddress : ercode_noaddress" alt="">
          </div>
          <div class="ercode-text">
            <span v-if="clientWidth > 750">本次巡展席位有限，现在就注册参会吧! AVEVA 剑维软件期待您的莅临，<br>
                  与我们一起探索数字化转型之路加速您的业务发展!
            </span>
            <span v-else>本次巡展席位有限，现在就注册参会吧!<br> AVEVA 剑维软件期待您的莅临，<br>
                  与我们一起探索数字化转型之路加速您的业务发展!
            </span>
            <div class="ercode-icon">
              <img :src="phoneImg" alt="">
              <span>会务联系人：邢强18519000588</span>
            </div>
            <!-- <span>尽享巡展精彩</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="moduleBox">
      <div class="moduleBox-title1">
         “剑指无界，共盟新程--2023 年剑维软件加速实现工控领域数字化转型” 巡展活动，在上海、广州、成都和北京已完美落幕。此次巡展活动专门邀请了拥有丰富行业经验的专家，与大家共同探讨工控领域如何深度应用数字化技术及解决方案，如何提升运营控制、生产管理等智能化水平，实现资产优化和精准决策。
      </div>
      <div class="moduleBox-title2">
         点击下方，获取活动精彩回顾 >>
      </div>
      <div class="moduleBox-listBox">
        <div class="moduleBox-list" v-for="(item, index) in activityList" :key="index" @click="goNext(item)">
          <div class="moduleBox-list-imgBox">
            <img :src="item.imgUrl1" class="moduleBox-list-img" alt="">
            <img :src="pauseIcon" class="moduleBox-list-imgPause" alt="">
          </div>
          <div class="moduleBox-list-contentBox">
            <div class="moduleBox-list-title1">{{item.extend1}}</div>
            <div class="moduleBox-list-timeBox">
              <div class="moduleBox-list-name">嘉宾：</div>
              <div class="moduleBox-list-text">{{item.extend2}}</div>
            </div>
            <div class="moduleBox-list-textBox">
              <div class="moduleBox-list-name">公司：</div>
              <div class="moduleBox-list-text">{{item.extend3}}</div>
            </div>
          </div>
          <div class="moduleBox-list-more">查看详细信息 >></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetWxShares } from "@/utils/common";
import { GetTreeSelect } from "@/api/Home.js"
import { ApiToken } from '@/utils/ApiToken'

export default {
  data(){
    return{
      currentIndex: 0,
      isNoAddressNav: true,
      bannerImg: require('@/assets/PC/newpage/banner.png'),
      bannerImg_m: require('@/assets/PC/newpage/banner_m.png'),
      timeImg: require('@/assets/PC/newpage/time.png'),
      timeImg_active: require('@/assets/PC/newpage/time_active.png'),
      addressImg: require('@/assets/PC/newpage/address.png'),
      addressImg_active: require('@/assets/PC/newpage/address_active.png'),
      ercode_noaddress: require('@/assets/PC/newpage/ercode_noaddress.png'),
      ercode_haveaddress: require('@/assets/PC/newpage/ercode_haveaddress.png'),
      iconErcodeImg: require('@/assets/PC/newpage/icon_ercode.png'),
      peopleMoreImg: require('@/assets/PC/newpage/people_more.png'),
      phoneImg: require('@/assets/PC/newpage/icon_phone.png'),

      pauseIcon: require('@/assets/PC/newpage/icon_pause.png'),
      timeIcon: require('@/assets/PC/newpage/icon_time.png'),
      textIcon: require('@/assets/PC/newpage/icon_text.png'),
      roles: [{
        name: '6月6日',
        address: '上海',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back1.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active1.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address1.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address_active1.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m1.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m_active1.png") + ")" },
        addressDetail1: '地点：上海雅居乐万豪侯爵酒店',
        addressDetail2: '地址：上海市黄浦区西藏中路555号',
        dateData: [
          {time: '09:00-09:15', content: '开场致辞', people: '崔静怡'},
          {time: '09:15-09:45', content: '工业软件的新组合：AVEVA Operations Control 应用场景及商业模式', people: '毕鹏辉'},
          {time: '09:45-10:25', content: '工业运营平台：AVEVA System Platform 2023 & OMI 新产品概览及功能展示', people: '尚真'},
          {time: '10:25-10:40', content: '茶歇', people: ''},
          {time: '10:40-11:10', content: '赋能流体行业，打造一站式数字化工厂解决方案', people: '客户分享'},
          {time: '11:10-11:40', content: '人机界面系统：AVEVA InTouch HMI 2023 版本功能更新概览及展示', people: '包震'},
          {time: '11:40-12:00', content: 'AVEVA System Platform 在大型水利行业集中控制和调度系统中的应用', people: '包震'},
          {time: '12:00-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: '高科技低碳工厂智慧厂务管理系统实践分享', people: '蓝鸟'},
          {time: '14:00-14:30', content: 'AVEVA 制造执行系统套件 Model driven MES 架构及功能简介', people: '张明杰'},
          {time: '14:30-14:55', content: '携手 AVEVA 走向数字化转型', people: '丹丹'},
          {time: '14:55-15:10', content: '大型玻纤企业数字化生产系统 - 基于 AVEVA System Platform OMI ', people: ''},
          {time: '15:10-15:20', content: '茶歇', people: ''},
          {time: '15:20-15:50', content: '工业数据库：Historian 2023 和 Communication Drivers 新功能 ', people: '胡绍宝'},
          {time: '15:50-16:10', content: 'UOC 运营中心应用开发助手集', people: 'RoviSys - ESI'},
          {time: '16:10-16:30', content: 'AVEVA 智慧城市·防洪排涝数字化应用', people: 'BJUT'},
          {time: '16:30-17:00', content: '借助 DPM & LSC 解决方案，优化半导体产业链', people: '李川'},
          {time: '17:00-17:10', content: '闭幕致辞', people: 'GW'},
        ],
        peopleBigData: [],
        peopleData: [{
          img: require('@/assets/PC/newpage/people1.png'),
          name: '崔静怡',
          address: '剑维软件大中华区',
          address_m: '剑维软件大中华区',
          position: '总经理'
        },{
          img: require('@/assets/PC/newpage/people27.png'),
          name: '王振新',
          address: '上海本钰智能设备<br>有限公司',
          address_m: '上海本钰智能设备<br>有限公司 ',
          position: '总经理'
        },{
          img: require('@/assets/PC/newpage/people6.png'),
          name: '陈呈禧',
          address: '罗福斯 RoviSys',
          address_m: '罗福斯 RoviSys',
          position: '项目工程师'
        },{
          img: require('@/assets/PC/newpage/people19.png'),
          name: '何炜铭',
          address: '上海蓝鸟机电有限公司',
          address_m: '上海蓝鸟机电<br>有限公司',
          position: '总裁'
        }],
        peopleBigData_m: [{
          img: require('@/assets/PC/newpage/people1.png'),
          name: '崔静怡',
          address: '剑维软件大中华区',
          address_m: '剑维软件大中华区',
          position: '总经理'
        },{
          img: require('@/assets/PC/newpage/people27.png'),
          name: '王振新',
          address: '上海本钰智能设备<br>有限公司',
          address_m: '上海本钰智能设备<br>有限公司 ',
          position: '总经理'
        },{
          img: require('@/assets/PC/newpage/people6.png'),
          name: '陈呈禧',
          address: '罗福斯 RoviSys',
          address_m: '罗福斯 RoviSys',
          position: '项目工程师'
        },{
          img: require('@/assets/PC/newpage/people19.png'),
          name: '何炜铭',
          address: '上海蓝鸟机电有限公司',
          address_m: '上海蓝鸟机电<br>有限公司',
          position: '总裁'
        }]
      },{
        name: '6月8日',
        address: '广州',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back2.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active2.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address2.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address_active2.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m2.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m_active2.png") + ")" },
        addressDetail1: '地点：广州粤海喜来登酒店',
        addressDetail2: '地址：广东省广州市天河区天河路208号',
        dateData: [
          {time: '09:00-09:15', content: '开场致辞', people: '崔静怡/Marc'},
          {time: '09:15-09:45', content: '工业软件的新组合：AVEVA Operations Control 应用场景及商业模式', people: '毕鹏辉'},
          {time: '09:45-10:25', content: '工业运营平台：AVEVA System Platform 2023 & OMI 新产品概览及功能展示', people: '尚真'},
          {time: '10:25-10:40', content: '茶歇', people: ''},
          {time: '10:40-11:10', content: '面向柔性电路制造关键工序的智能监控分析系统', people: '胡跃明教授'},
          {time: '11:10-11:40', content: '人机界面系统：AVEVA InTouch HMI 2023 版本功能更新概览及展示', people: '翁添华'},
          {time: '11:40-12:10', content: 'AVEVA System Platform 系统平台 SCADA 系统 “最佳实践” 设计规范', people: ''},
          {time: '12:10-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: '高科技低碳工厂智慧厂务管理系统实践分享', people: '李川'},
          {time: '14:00-14:20', content: 'AVEVA 制造执行系统套件 Model driven MES 架构及功能简介', people: '丹丹'},
          {time: '14:20-14:45', content: '携手 AVEVA 走向数字化转型', people: 'Mingjie zhang'},
          {time: '14:45-15:05', content: 'UOC 运营中心应用开发助手集', people: ''},
          {time: '15:05-15:20', content: '茶歇', people: ''},
          {time: '15:20-15:50', content: '工业数据库：Historian 2023 和 Communication Drivers 新功能', people: 'RoviSys - ESI'},
          {time: '15:50-16:10', content: 'AVEVA 智慧城市·防洪排涝数字化应用', people: '卓梓融 '},
          {time: '16:10-16:40', content: '借助 DPM & LSC 解决方案，优化半导体产业链', people: 'UTBJ'},
          {time: '16:40-17:10', content: '大型玻纤企业数字化生产系统 - 基于 AVEVA System Platform OMI ', people: '蓝鸟'},
          {time: '17:10-17:20', content: '闭幕致辞', people: 'GW'},
        ],
        peopleBigData: [{
          img: require('@/assets/PC/newpage/people_big1.png'),
          name: '崔静怡',
          address: '剑维软件大中华区',
          address_m: '剑维软件大中华区',
          position: '总经理'
        },{
          img: require('@/assets/PC/newpage/people8.png'),
          name: '胡跃明',
          address: '华南理工大学',
          address_m: '华南理工大学',
          position: '教授'
        }],
        peopleData: [{
          img: require('@/assets/PC/newpage/people9.png'),
          name: '翁添华',
          address: '广州确任自动化<br>系统有限公司',
          address_m: '广州确任自动化<br>系统有限公司',
          position: '技术总监'
        },{
          img: require('@/assets/PC/newpage/people6.png'),
          name: '陈呈禧',
          address: '罗福斯 RoviSys',
          address_m: '罗福斯 RoviSys',
          position: '项目工程师'
        },{
          img: require('@/assets/PC/newpage/people7.png'),
          name: '李川',
          address: '广州确任自动化<br>系统有限公司',
          address_m: '广州确任自动化<br>系统有限公司',
          position: '总经理'
        }]
      },{
        name: '6月13日',
        address: '成都',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back3.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active3.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address3.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address_active3.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m3.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m_active3.png") + ")" },
        // addressDetail1: '地点：成都世纪城天堂洲际大饭店',
        // addressDetail2: '地址：四川省成都市武侯区高新区世纪城路88号',
        addressDetail1: '地点：成都世纪城国际会议中心',
        addressDetail2: '地址：四川省成都市武侯区世纪城路198号',
        dateData: [
          {time: '09:00-09:15', content: '开场致辞', people: '崔静怡/Marc'},
          {time: '09:15-09:45', content: '工业软件的新组合：AVEVA Operations Control 应用场景及商业模式', people: '尚真'},
          {time: '09:45-10:25', content: '工业运营平台：AVEVA System Platform 2023 & OMI 新产品概览及功能展示', people: 'Shaobao HU'},
          {time: '10:25-10:40', content: '茶歇', people: ''},
          {time: '10:40-11:10', content: 'AVEVA 生态在生物制药行业的应用', people: '张改'},
          {time: '11:10-11:40', content: '人机界面系统：AVEVA InTouch HMI 2023 版本功能更新概览及展示', people: '李龙泉'},
          {time: '11:40-12:00', content: 'AVEVA 智慧城市·防洪排涝数字化应用', people: ''},
          {time: '12:00-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: '电子工业工厂智慧厂务管理系统实践分享', people: '客户分享'},
          {time: '14:00-14:30', content: '工业数据库：Historian 2023 和 Communication Drivers 新功能 ', people: '毕鹏辉'},
          {time: '14:30-15:00', content: 'AVEVA 工控平台在白酒行业的应用与前景展望', people: 'GW'},
          // {time: '15:00-15:10', content: '茶歇', people: ''},
          {time: '15:00-15:30', content: '借助 DPM & LSC 解决方案，优化半导体产业链 ', people: '蓝鸟'},
          {time: '15:30-15:50', content: '大型玻纤企业数字化生产系统 - 基于 AVEVA System Platform OMI', people: 'UTBJ'},
          {time: '15:50-16:10', content: 'UOC 运营中心应用开发助手集', people: '李川'},
          {time: '16:10-16:20', content: '闭幕致辞', people: 'GW'},
        ],
        peopleBigData: [],
        peopleBigData_m: [{
          img: require('@/assets/PC/newpage/people_big25.png'),
          name: '刘新平',
          address: '北京高威科电气技术<br>股份有限公司',
          address_m: '北京高威科电气技<br>术股份有限公司',
          position: '总裁'
        },{
          img: require('@/assets/PC/newpage/people29.png'),
          name: '何良平',
          address: '江苏融科装备科技<br>有限公司',
          address_m: '江苏融科装备科技<br>有限公司',
          position: '设施软件研发部经理'
        },{
          img: require('@/assets/PC/newpage/people26.png'),
          name: '张改',
          address: '成都生物制品研究所',
          address_m: '成都生物制品<br>研究所',
          position: '总经理助理'
        },{
          img: require('@/assets/PC/newpage/people22.png'),
          name: '李龙泉',
          address: '四川高威新潮 ',
          address_m: '四川高威新潮 ',
          position: 'AVEVA 软件技术总监',
          type: 'small',
        }],
        peopleData: [{
          img: require('@/assets/PC/newpage/people_big25.png'),
          name: '刘新平',
          address: '北京高威科电气技术<br>股份有限公司',
          address_m: '北京高威科电气技<br>术股份有限公司',
          position: '总裁'
        },{
          img: require('@/assets/PC/newpage/people29.png'),
          name: '何良平',
          address: '江苏融科装备科技<br>有限公司',
          address_m: '江苏融科装备科技<br>有限公司',
          position: '设施软件研发部经理'
        },{
          img: require('@/assets/PC/newpage/people26.png'),
          name: '张改',
          address: '成都生物制品研究所',
          address_m: '成都生物制品<br>研究所',
          position: '总经理助理'
        },{
          img: require('@/assets/PC/newpage/people22.png'),
          name: '李龙泉',
          address: '四川高威新潮 ',
          address_m: '四川高威新潮 ',
          position: 'AVEVA 软件技术总监',
          type: 'small',
        }]
      },{
        name: '6月15日',
        address: '北京',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back4.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active4.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address4.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_address_active4.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m4.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_m_active4.png") + ")" },
        addressDetail1: '地点：北京粤财JW万豪酒店',
        addressDetail2: '地址：北京市西城区宣武门外大街18号',
        dateData: [
          {time: '09:00-09:15', content: '开场致辞', people: '崔静怡/Marc'},
          {time: '09:15-09:45', content: '工业软件的新组合：AVEVA Operations Control 应用场景及商业模式', people: '尚真'},
          {time: '09:45-10:25', content: '工业运营平台：AVEVA System Platform 2023 & OMI 新产品概览及功能展示', people: 'Shaobao HU'},
          {time: '10:25-10:40', content: '茶歇', people: ''},
          {time: '10:40-11:10', content: '系统平台在水行业的应用', people: '客户分享'},
          {time: '11:10-11:40', content: '人机界面系统：AVEVA InTouch HMI 2023 版本功能更新概览及展示', people: '王明明'},
          {time: '11:40-12:10', content: '借助 DPM & LSC 解决方案，优化半导体产业链', people: ''},
          {time: '12:10-13:30', content: '午餐', people: ''},
          {time: '13:30-13:50', content: 'AVEVA 工控平台在烟草行业的应用与前景展望', people: '客户-航天拓扑'},
          {time: '13:50-14:20', content: '工业数据库：Historian 2023 和 Communication Drivers 新功能 ', people: '毕鹏辉'},
          {time: '14:20-14:50', content: '高科技低碳工厂智慧厂务管理系统实践分享', people: '丹丹'},
          // {time: '14:50-15:10', content: '茶歇', people: 'UTBJ'},
          {time: '14:50-15:20', content: 'AVEVA 工控平台在白酒行业的应用与前景展望', people: ''},
          {time: '15:20-15:40', content: '大型玻纤企业数字化生产系统 - 基于 AVEVA System Platform OMI', people: '蓝鸟'},
          {time: '15:40-16:10', content: 'AVEVA System Platform 系统平台 SCADA 系统 “最佳实践” 设计规范', people: 'GW'},
          {time: '16:10-16:30', content: 'AVEVA 智慧城市·防洪排涝数字化应用', people: '王建华'},
          {time: '16:30-16:50', content: '半导体行业深度数字化交付实践分享', people: '王建华'},
          {time: '16:50-17:00', content: '闭幕致辞', people: 'UTBJ'},
        ],
        peopleBigData: [{
          img: require('@/assets/PC/newpage/people15.png'),
          name: '程万鹏',
          address: '苏伊士环境科技<br>（北京）有限公司',
          address_m: '苏伊士环境科技<br>（北京）有限公司',
          position: '计算机专家'
        },{
          img: require('@/assets/PC/newpage/people16.png'),
          name: '任旭东',
          address: '北京航天拓扑高科技<br>有限责任公司',
          address_m: '北京航天拓扑高科技有限责任公司',
          position: '项目总监'
        },{
          img: require('@/assets/PC/newpage/people_big28.png'),
          name: '唐明',
          address: '四川星环纪元科技<br>发展有限公司',
          address_m: '四川星环纪元科技发展有限公司',
          position: '创始人/产品总监'
        },{
          img: require('@/assets/PC/newpage/people11.png'),
          name: '李宝文',
          address: '北京汉锦电子自动化<br>系统有限公司',
          address_m: '北京汉锦电子自动化系统有限公司',
          position: '高级工程师'
        }],
        peopleData: [{
          img: require('@/assets/PC/newpage/people15.png'),
          name: '程万鹏',
          address: '苏伊士环境科技<br>（北京）有限公司',
          address_m: '苏伊士环境科技<br>（北京）有限公司',
          position: '计算机专家'
        },{
          img: require('@/assets/PC/newpage/people16.png'),
          name: '任旭东',
          address: '北京航天拓扑高科技<br>有限责任公司',
          address_m: '北京航天拓扑高科技有限责任公司',
          position: '项目总监'
        },{
          img: require('@/assets/PC/newpage/people_big28.png'),
          name: '唐明',
          address: '四川星环纪元科技<br>发展有限公司',
          address_m: '四川星环纪元科技发展有限公司',
          position: '创始人/产品总监'
        },{
          img: require('@/assets/PC/newpage/people11.png'),
          name: '李宝文',
          address: '北京汉锦电子自动化<br>系统有限公司',
          address_m: '北京汉锦电子自动化系统有限公司',
          position: '高级工程师'
        }],
      }],
      peopleBigData: [],
      peopleData: [],
      activityData: [
        {
          img: require('@/assets/PC/newpage/activity1.png'),
        },{
          img: require('@/assets/PC/newpage/activity2.png'),
        },{
          img: require('@/assets/PC/newpage/activity3.png'),
        },{
          img: require('@/assets/PC/newpage/activity4.png'),
        },{
          img: require('@/assets/PC/newpage/activity5.png'),
        }
      ],
      dateData: [],
      peopleBigData_m: [],
      addressDetail1: '',
      addressDetail2: '',


      activityList: []
    }
  },
  mounted() {
    if(this.$route.query.address == 'site'){
      this.isNoAddressNav = false
    }else{
      this.isNoAddressNav = true
    }
    if(this.$route.query.index){
      let index = this.$route.query.index ? this.$route.query.index : 0
      this.currentIndex = index
      this.dateData = this.roles[index].dateData
      this.addressDetail1 = this.roles[index].addressDetail1
      this.addressDetail2 = this.roles[index].addressDetail2
      this.peopleData = this.roles[index].peopleData
      this.peopleBigData = this.roles[index].peopleBigData
      if(index == 2 || index == 0){
        this.peopleBigData_m = this.roles[index].peopleBigData_m
      }
    }else{
      this.dateData = this.roles[0].dateData
      this.addressDetail1 = this.roles[0].addressDetail1
      this.addressDetail2 = this.roles[0].addressDetail2
      this.peopleData = this.roles[0].peopleData
      this.peopleBigData = this.roles[0].peopleBigData
      this.peopleBigData_m = this.roles[0].peopleBigData_m
    }
    let data = {
      pid: ApiToken.roadShowId,
      id: ''
    }
    this.GetTreeSelect(data)
    
    let obj = {
      title: 'AVEVA 剑盟社区',
      // desc: '剑指无界，共盟新程'
    }
    GetWxShares(obj)
  },
  methods: {
    GetTreeSelect(data){
      GetTreeSelect(data).then(res =>{
        if(res.status == 1){
          this.activityList = res.data
        }else{
          this.$toast(res.message);
        }
        
      })
    },
    clickRadio (item, index){
      if(this.currentIndex != index){
        this.currentIndex = index
        this.dateData = item.dateData
        this.addressDetail1 = item.addressDetail1
        this.addressDetail2 = item.addressDetail2
        this.peopleData = item.peopleData
        this.peopleBigData = item.peopleBigData
        this.peopleBigData_m = item.peopleBigData_m
      }
    },
    goNext(item) {
      window.open(item.extend4)
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  },
};
</script>
<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.wrap{
  width: 100%;
  overflow: hidden;
  font-size: 30px;
}
.banner{
  width: 100%;
  height: auto;
  display: block;
  img{
    width: 100%;
    height: auto;
    display: block;
  }
}
.module, .moduleBox{
  width: 1200px;
  margin: 0 auto 70px;
}
.moduleBox-title1{
  text-indent: 28px; font-size: 16px; line-height: 32px;padding:62px 0 0; 
}
.moduleBox-title2{
  display: inline-block; font-size: 16px; line-height: 32px;padding:0 0 0; color: #6B18A8; 
}
.module-intro{font-size: 16px; line-height: 32px;padding: 81px 0 37px; 
  p{font-size: 16px; text-indent: 32px; margin-bottom: 20px;}
}
.module-title{width: 100%;font-size: 20px; color: #434343; font-weight: bold; margin: 0 0 20px;}
.module-box{
  .module-box-content{
    width: 300px;
    height: 97px;
    background-size: 300px 97px;
    font-size: 20px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 30px;
    .module-content-nav{
      width: 100%;
      display: flex;
      .nav-time, .nav-address{
        display: flex;
        align-items: center;
        img{
          margin-right: 7px;
        }
        span{
          font-size: 16px;
          margin-top: -3px;
        }
      }
      .nav-time{
        img{
          width: 18px;
          height: 18px;
        }
      }
      .nav-address{
        margin-left: 20px;
        img{
          width: 16px;
          height: 20px;
        }
      }
    }
    
  }
  .module-box-address{
    width: 300px;
    height: 147px;
    background-size: 300px 147px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 15px;
    .module-content-nav{
      margin-top: 10px;
    }
    .module-content-address{
      margin-top: 32px;
      p{
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
  .select-active{
    position: relative; 
    font-size: 24px; 
    color: #fff; 
    cursor: default;}
  .select-active::before{
    content: '';
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-top:12px solid transparent;
    border-bottom: 12px solid #FFFFFF;
    border-left:8px solid transparent;
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
  }
  .select-active::after{
    width: 0px;
    height: 0px;
    border-right: 9px solid transparent;
    border-top:12px solid transparent;
    border-bottom:13px solid #6b18a8;
    border-left:9px solid transparent;
    display: block;
    content:'';
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .module-box-content:nth-of-type(4n) { margin-right: 0 }
}
.module-date-box{
  margin-top: 40px;
  width: 100%;
  border: 1px solid #6B18A8;
  border-radius: 16px;
  position: relative;
  z-index: 9;
  padding: 0 35px 67px;
  .date-title{
    font-size: 28px;
    font-weight: bold;
    margin-top: 45px;
  }
}

.item{
  margin-top: 19px;
  .item-more{
    margin-top: 10px;
    font-size: 14px;
    color: #5f6368;
  }
  .item-header-left{
    width: 20%;
  }
  .item-header-center{
    width: 60%;
  }
  .item-header-right{
    width: 20%;
  }
  .item-header-center, .item-header-right{
    padding: 0 !important;
    text-align: center !important;
  }
  table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #aaa;
    tr{
      td:nth-child(1){
        border-right: 1px solid rgba(167, 167, 167, 0.36);;
      }
      td:nth-child(2){
        text-align: left;
        padding-left: 100px;
        border-right: 1px solid rgba(167, 167, 167, 0.36);;
      }
      td:nth-child(3){
        text-align: left;
        padding-left: 95px;
        padding-right: 5px;
      }
      td{
        vertical-align: middle;
        height: 40px;
        border: 1px solid #F2F2F2;
        text-align: center;
        color: #000000;
      }
      &:first-of-type{
        background-image: url('../../assets/PC/newpage/table_header.png');
        background-size: 1127px 52px;
        td{
          background-color: transparent !important;
          font-weight: bold;
          color: #fff;
          height: 52px;
          font-size: 16px;
        }
      }
      &:nth-child(even){
        td{
          background-color: #fff;
        }
      }
      &:nth-child(odd){
        td{
          background-color: #F2F2F2;
        }
      }
    }
    td{
      padding: 10px;
    }
  }
}

.people-big-box{
  justify-content: center;
  // align-items: center;
  align-items: flex-start;
}
.people-box, .people-big-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 23px;
  .people:nth-of-type(5n){
    margin-right: 0;
  }
  .people{
    width: calc((100% - 40px) / 5);
    border-bottom: none;
    border-radius: 20px;
    margin-right: 10px;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    vertical-align: top;
    img{
      width: 157px;
      height: 150px;
      object-fit: fill;
      margin-bottom: 16px;
      margin-top: 36px;
    }
    .people-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      .people-name{
        font-weight: bold;
        font-size: 18px;
        color: #6B18A8;
      }
      .people-address{
        margin-top: 9px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
      .people-position{
        font-size: 16px;
        line-height: 22px;
      }
    }
    
    .people-bottom{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 13px;
      background: #6A1BB2;
      border-radius: 0 0 20px 20px;
    }
  }
  .people_header:nth-of-type(2n){
    margin: 0 40px;
  }
  .people_header{
    margin: 0 40px;
    img{
      width: 227px;
      height: 217px;
    }
    .small_img{
      width: 157px;
      height: 150px;
    }
  }
  .small_box:nth-of-type(2n){
    margin: 0 20px;
  }
  .small_box{
    margin: 0 20px;
  }
}
.people-big-box-chengdu{
  justify-content: center;
  align-items: center;
  .people_header{
    margin: 0 20px;
    img{
      width: 205px;
      height: 197px;
    }
  }
  .small_box{
    padding-top: 25px
  }
}
.activity-box{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .activity:nth-of-type(5n){
    margin-right: 0;
  }
  .activity{
    width: 216px;
    height: 89px;
    border: 1px solid #6B18A8;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 213px;
      max-height: 89px;
      object-fit: fill;
    }
  }
}
.ercode-box{
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ercode-img{
    width: 184px;
    height: 184px;
    margin-right: 23px;
    img{
      width: 100%
    }
  }
  .ercode-text{
    display: flex;
    flex-direction: column;
    .ercode-icon{
      display: flex;
      align-items: center;
      margin-top: 10px;
      img{
        background: #6B18A8;
        width: 20px;
        height: 20px;
        margin-right: 3px;
        border-radius: 5px;
      }
      span{
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }
    }
    span{
      font-size: 18px;
      line-height: 29px;
    }
  }
}


.moduleBox-listBox{
  margin: 41px 0 75px;
  display: flex;
  flex-wrap: wrap;
  .moduleBox-list:nth-child(4n){
    margin-right: 0;
  }
  .moduleBox-list{
    width: calc((100% - 14px) / 4);
    max-width: 288px;
    margin: 0 14px 17px 0;
    border-radius: 6px;
    background: #f2f2f2;
    padding-bottom: 30px;
    position: relative;
    cursor: pointer;
    .moduleBox-list-imgBox{
      width: 288px;
      height: 163px;
      background: #000;
      position: relative;
      cursor: pointer;
      border-radius: 6px 6px 0 0;
      .moduleBox-list-img{
        width: 100%;
        object-fit: fill;
        border-radius: 6px 6px 0 0;
      }
      .moduleBox-list-imgPause{
        width: 55px;
        height: 56px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .moduleBox-list-contentBox{
      width: 100%;
      padding: 25px 17px 23px;
      border-radius: 0 0 6px 6px;
      background: #f2f2f2;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: relative;
      .moduleBox-list-title1{
        height: 78px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 26px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .moduleBox-list-timeBox, .moduleBox-list-textBox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        img{
          width: 16px;
          height: 16px;
        }
        .moduleBox-list-name{
          font-size: 16px;
          color: #000;
          line-height: 22px;
          font-weight: bold;
        }
        .moduleBox-list-text{
          font-size: 16px;
          color: #000;
          line-height: 22px;
          flex: 1;
        }
      }
      .moduleBox-list-textBox{
        align-items: flex-start;
        img{
          width: 15px;
          height: 17px;
        }
      }
      
    }
    .moduleBox-list-more{
      position: absolute;
      left: 17px;
      bottom: 28px;
      color: #6B18A8;
      font-size: 16px;
      cursor: pointer;
    }
  }
}


@media (max-width: 750px) {
  .banner{
    img{
      width: vw(750);
    }
  }
  .module, .moduleBox{
    width: 100%;
    padding: 0 vw(40);
    margin-bottom: vw(70);
  }
  .moduleBox-title1{font-size: vw(24); line-height: vw(36);padding: vw(55) 0 0;}
  .moduleBox-title2{font-size: vw(24); line-height: vw(36);padding: vw(10) 0 0;}
  .module-intro{font-size: vw(24); line-height: vw(36);padding: vw(55) 0 vw(33);
    p{font-size: vw(24);text-indent: vw(32); margin-bottom: vw(30);}
  }
  .module-title{font-size: vw(28); margin: 0 0 vw(20)}
  .module-box{
    .module-box-content{
      width: vw(167); 
      height: vw(152);
      background-size: vw(167) vw(152);
      padding-left: vw(15);
      padding-top: vw(20);
      margin-right: vw(5);
      flex-direction: column;
      align-items: flex-start;
      .module-content-nav{
        flex-direction: column;
        .nav-time, .nav-address{
          display: flex;
          align-items: center;
          img{
            margin-right: vw(7);
          }
          span{
            margin-top: 0;
            font-size: vw(24);
          }
        }
        .nav-time{
          img{
            width: vw(18);
            height: vw(18);
          }
        }
        .nav-address{
          margin-top: vw(10);
          margin-left: 0;
          img{
            width: vw(16);
            height: vw(20);
          }
        }
      }
      
    }
    .select-active::before{
      border-right: 8px solid transparent;
      border-top:12px solid transparent;
      border-bottom: 12px solid #FFFFFF;
      border-left:8px solid transparent;
      bottom: vw(-51);
    }
    .select-active::after{
      border-right: 9px solid transparent;
      border-top:12px solid transparent;
      border-bottom:13px solid #6b18a8;
      border-left:9px solid transparent;
      bottom: vw(-51);
    }
  }

  .module-date-box{
    margin-top: vw(49);
    border-radius: vw(16);
    padding: 0 vw(28) vw(69);
    .date-title{
      font-size: vw(24);
      margin-top: vw(38);
      color: #6A1BB2;
    }
    .address-mobile{
      width: 100%;
      background: #EFE1F9;
      border-radius: vw(16);
      padding: vw(25) vw(10);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: vw(35);
      p{
        font-size: vw(24);
        line-height: vw(40);
      }
    }
  }

  .item{
    margin-top: vw(16);
    .item-more{
      margin-top: vw(15);
      font-size: vw(22);
    }
    .item-header-left{
      width: 25%;
    }
    .item-header-center{
      width:50%;
    }
    .item-header-right{
      width: 25%;
    }
    table{
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #aaa;
      tr{
        td:nth-child(2){
          padding-left: vw(10);
        }
        td:nth-child(3){
          padding-left: vw(10);
          padding-right: vw(10);
        }
        td{
          font-size: vw(22);
          height: auto;
        }
        &:first-of-type{
          background-image: url('../../assets/PC/newpage/table_header_m.png');
          background-size: vw(623) vw(56);
          td{
            height: auto;
            font-size: vw(24);
          }
        }
      }
      td{
        padding: vw(13);
      }
    }
  }
  .people-box, .people-big-box{
    margin-top: vw(20);
    align-items: flex-start;
    .people:nth-of-type(3n){
      margin-right: 0;
    }
    .people{
      width:calc((100% - 10px) / 3);
      border-radius: vw(20);
      margin-right: 0;
      margin-top: vw(25);
      img{
        width: vw(163);
        height: vw(163);
        object-fit: fill;
        margin-top: 0;
        margin-bottom: 0;
      }
      .people-content{
        .people-name{
          font-size: vw(24);
          font-weight: bold;
        }
        .people-address{
          margin-top: vw(9);
          font-size: vw(24);
          text-align: center;
          line-height: vw(36);
        }
        .people-position{
          font-size: vw(24);
          margin-bottom: 0;
          text-align: center;
        }
      }
      
      .people-bottom{
        height: vw(13);
        border-radius: 0 0 vw(30) vw(30);
        bottom: vw(-1)
      }
    }
    .people_header:nth-of-type(2n){
      margin-right: 0;
    }
    .people_header{
      margin: 0 vw(20);
      img{
        width: vw(200);
        height: vw(200);
      }
      .small_img{
        // width: vw(163);
        // height: vw(163);
        width: vw(200);
        height: vw(200);
      }
    }
    .small_box:nth-of-type(2n){
      margin: 0 vw(20) !important;
    }
    .small_box{
      width: 40%;
      margin: 0 vw(20) !important;
    }
  }

  .people-big-box{
    .people:nth-of-type(2n){
      margin: 0 vw(20) !important;
    }
    .people:nth-of-type(3n){
      margin: 0 vw(20) !important;
    }
    .people{
      width:calc((100% - 80px) / 2) !important;
    }
  }

  .activity-box{
    margin-top: vw(20);
    justify-content: center;
    .activity:nth-of-type(3n){
      margin-right: 0;
    }
    .activity{
      width: vw(200);
      height: vw(73);
      margin-right: vw(10);
      margin-bottom: vw(10);
      img{
        max-width: vw(155);
        max-height: vw(44);
        object-fit: fill;
      }
    }
    .activity:nth-child(1){
      img{
        max-width: vw(93);
        max-height: vw(44);
      }
    }
    .activity:nth-child(2){
      img{
        max-width: vw(155);
        max-height: vw(40);
      }
    }
    .activity:nth-child(3){
      img{
        max-width: vw(73);
        max-height: vw(67);
      }
    }
    .activity:nth-child(4){
      img{
        max-width: vw(118);
        max-height: vw(51);
      }
    }
    .activity:nth-child(5){
      img{
        max-width: vw(124);
        max-height: vw(36);
      }
    }
  }
  .ercode-box{
    margin-top: vw(45);
    flex-direction: column;
    align-items: center;
    .ercode-img{
      width: vw(216);
      height: vw(216);
      margin-right: 0;
    }
    .ercode-text{
      display: flex;
      flex-direction: column;
      .ercode-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: vw(60);
        margin-bottom: vw(5);
        img{
          width: vw(23);
          height: vw(22);
          margin-right: vw(5);
        }
        span{
          font-size: vw(24);
          line-height: vw(24);
          font-weight: bold;
          margin-bottom: vw(-6)
        }
      }
      span{
        font-size: vw(24);
        line-height: vw(42);
        text-align: center;
        font-weight: bold;
      }
      &>span{
        margin-top: vw(30)
      }
    }
  }

  .moduleBox-listBox{
    margin: vw(60) 0 vw(73);
    .moduleBox-list:nth-child(2n){
      margin-right: 0;
    }
    .moduleBox-list{
      // width: calc((100% - 14px) / 2);
      width: vw(327);
      max-width: vw(327);
      margin: 0 vw(12) vw(15) 0;
      border-radius: 0 0 vw(6) vw(6);
      .moduleBox-list-imgBox{
        width: vw(327);
        height: vw(184);
        .moduleBox-list-img{
          width: 100%;
          border-radius: vw(6) vw(6) 0 0;
          
        }
        .moduleBox-list-imgPause{
          width: vw(63);
          height: vw(63);
        }
      }
      .moduleBox-list-contentBox{
        padding: vw(21) vw(20) vw(9);
        border-radius: 0 0 vw(6) vw(6);
        .moduleBox-list-title1{
          height: vw(148);
          font-size: vw(26);
          margin-bottom: vw(29);
          line-height: vw(39);
          -webkit-line-clamp: 4;
        }
        .moduleBox-list-timeBox, .moduleBox-list-textBox{
          margin-bottom: vw(17);
          img{
            width: vw(22);
            height: vw(22);
          }
          .moduleBox-list-name{
            font-size: vw(25);
            line-height: vw(34);
          }
          .moduleBox-list-text{
            font-size: vw(25);
            line-height:  vw(34);
          }
        }
        .moduleBox-list-textBox{
          img{
            width: vw(20);
            height: vw(23);
          }
          .moduleBox-list-text{
            line-height: vw(34);
          }
        }
      }
      .moduleBox-list-more{
        left: vw(20);
        bottom: vw(33);
        font-size: vw(24);
      }
    }
  }
}
</style>
